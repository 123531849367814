import React from "react";
import { motion } from "framer-motion";
import { t } from "i18next";

export const Cover: React.FC<any> = ({}) => {
  const goToWebApp = () => {
    window.location.href = "https://web.payqin.com";
  };
  return (
    <section className="flex flex-col pt-10 md:pt-24 lg:pt-40">
      <div className="text-center px-10 md:px-30 2xl:px-30">
        <h1 className="text-2xl md:text-6xl lg:text-8xl font-bold leading-normal">
          {t("index.welcome")}
        </h1>
        <p className="mt-8 text-gray-800 font-extralight md:font-light text-sm md:text-base lg:text-lg">
          {t("index.welcomeSub")}
        </p>
      </div>
      <div className="flex justify-center gap-x-4 mt-8">
        <motion.button
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          className="flex text-black px-3 md:px-6 py-3 bg-white text-center text-xs md:text-sm rounded-full hover:bg-payqinBlue hover:text-white border border-payqinBlue"
          onClick={goToWebApp}
        >
          {t("index.openAccount")}
        </motion.button>
        <motion.button
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          className="flex text-black px-3 md:px-6 py-3 bg-white text-center text-xs md:text-sm border border-payqinBlue rounded-full hover:text-white hover:bg-payqinBlue"
          onClick={goToWebApp}
        >
          {t("index.sendMoneyNow")}
        </motion.button>
      </div>
    </section>
  );
};
