import React from "react";
import { Card } from "../../../utils/card";
import { t } from "i18next";
import {
  simplePhysicalCardImg,
  transferImg,
  walletImg,
} from "../../../constants/appConstants";

export const Possibilities: React.FC<any> = ({}) => {
  return (
    <div className="mt-28 lg:mt-60 lg:h-[39rem] p-4 md:p-14 mb-14 xl:mb-[25rem] lg:mb-[15rem] rounded-lg bg-gradient-to-r from-payqinGradiantLeft to-payqinGradiantRight">
      <div className=" lg:grid lg:grid-cols-3">
        <h1 className="font-bold text-xl md:text-4xl lg:text-5xl col-span-2 lg:tracking-wider lg:leading-snug">
          {t("index.unlockPossibility")}
        </h1>
        <p className="font-extralight md:font-medium text-xs md:text-base mt-4 md:mt-3 lg:mt-0">
          {t("index.possibilitySub")}
        </p>
      </div>

      <div className="block mt-14 lg:flex justify-center gap-x-4">
        <Card
          title={t("index.virtualCard")}
          text={t("index.virtualCardSub")}
          img={walletImg}
        />
        <Card
          title={t("index.physicalCard")}
          text={t("index.physicalCardSub")}
          img={simplePhysicalCardImg}
        />
        <Card
          title={t("index.moneyTransfer")}
          text={t("index.moneyTransferSub")}
          img={transferImg}
        />
      </div>
    </div>
  );
};
