import React from "react";
import { CustomLang } from "../assets/customLangFlag";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { CustomRSIcon } from "../assets/customRSIcons";
import {
  facebookLogo,
  instagramLogo,
  linkedinLogo,
  payqinLogo,
  pciLogo,
  twitterLogo,
} from "../constants/appConstants";

export const Footer: React.FC<any> = ({}) => {
  const { i18n } = useTranslation("common");
  let lang = "fr";

  const currentLang = i18n.language.toUpperCase();

  const handleChangelanguage = () => {
    if (i18n.language === "fr") {
      localStorage.setItem("language", "en");
      lang = "en";
    } else {
      localStorage.setItem("language", "fr");
      lang = "fr";
    }

    i18n.changeLanguage(lang).then(() => window.location.reload());
  };

  return (
    <footer className="px-4 w-full font-light pb-5 text-payqinGrayText">
      <div className="container mx-auto h-full  rounded-lg ">
        <div className="flex justify-between items-center h-full">
          <img src={payqinLogo} alt="payqin_logo" className="max-h-6" />

          <ul className="hidden md:flex gap-x-20">
            {/* <li>
              <Link href="/services">
                <p>Products</p>
              </Link>
            </li> */}
            <li>
              <a href="/pricing">
                <p>{t("index.pricing")}</p>
              </a>
            </li>
            <li>
              <a href="https://blog.payqin.com">
                <p>Blog</p>
              </a>
            </li>
            <li>
            <a
              href="https://policies.payqin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("index.terms_conditions")}
            </a>
            </li>
            <li>
              <a className="text-sm" href="#" onClick={handleChangelanguage}>
                <CustomLang lang={currentLang} />
              </a>
            </li>
          </ul>

          <div>
            <ul className="flex items-center gap-x-10">
              <CustomRSIcon
                imgUrl={facebookLogo}
                alt="facebook_logo"
                urlTo="https://www.facebook.com/payqin"
              />

              <CustomRSIcon
                imgUrl={twitterLogo}
                alt="twitter_logo"
                urlTo="https://twitter.com/PayQinn"
              />

              <CustomRSIcon
                imgUrl={instagramLogo}
                alt="instagram_logo"
                urlTo="https://www.instagram.com/payqin/"
              />

              <CustomRSIcon
                imgUrl={linkedinLogo}
                alt="linkedin_logo"
                urlTo="https://www.linkedin.com/company/payqin/"
              />
            </ul>
          </div>
        </div>
        <div className="mt-10">
          <div className="text-left md:text-center">
            <p className="text-sm">
              {t("index.payqinInc")} {t("index.registrationNumber")}
            </p>
            <p className="text-sm">{t("index.payqinFin")}</p>
          </div>
        </div>
        <div className="flex mt-10 justify-between items-center">
          <p className="text-sm">{t("index.payqinRight")}</p>
          <img src={pciLogo} alt="pci_dss_logo" className="max-h-[2rem]" />
        </div>
      </div>
    </footer>
  );
};
